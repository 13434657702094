import { Fragment, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import CustomAppBar from "./components/Appbar/CustomAppBar";
import Home from "./components/Home";
import Login from "./components/Login/login";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import Threshold from "./components/Threshold";
import { PathsUrls } from "./utils/Data";

function App() {
  //#region HOOKS
  const location = useLocation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(location.pathname !== PathsUrls.login);
  }, [location]);

  //#endregion

  return (
    <Fragment>
      {show && <CustomAppBar />}
      <Routes>
        <Route path="/" element={<Navigate to={PathsUrls.login} />} />
        <Route path={PathsUrls.login} element={<Login />} />
        <Route path={PathsUrls.home} element={<Home />} />
        <Route path={PathsUrls.threshold} element={<Threshold />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Fragment>
  );
}

export default App;
