import { Backdrop, Button, CircularProgress } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useVerifyUserMutation } from "../../services/HomeApi";
import FeedbackDialog from "../../utils/FeedBackDialog";

export default function VerifyUser({ userId, rewardId, score, isVerified, changeStatus }) {
  //#region API && HOOKS
  const userToken = useSelector((state) => state.home.userToken);
  const [apiStateIndex, setApiStateIndex] = useState({
    index: 0,
    msg: "",
    callBack: null,
  });
  //#endregion

  //#region VERIFY API
  const [
    getVerifyUserDetails,
    {
      isLoading: isLoadingGetVerifyUserDetailsApi,
      error: getVerifyUserDetailsAPIError,
      isSuccess: isSuccessGetVerifyUserDetailsAPI,
      data: getVerifyUserDetailsData,
    },
  ] = useVerifyUserMutation();
  //#endregion

  useEffect(() => {
    if (getVerifyUserDetailsData) {
      changeStatus(getVerifyUserDetailsData.data.verified);
      //console.log(getVerifyUserDetailsData.data.verified);
    }
  }, [getVerifyUserDetailsData]);

  //#region VERIFY BTN CLICK
  const checkVerify = () => {
    let index = 0;
    let msg = "";
    if (userId) {
      index = 1;
      msg = "";
      getVerifyUserDetails({
        userToken: userToken,
        userId: userId,
        rewardId: rewardId,
        score: score,
      });
    } else {
      index = 2;
      msg = "FIELD CAN'T BE EMPTY";
    }

    setApiStateIndex({
      index: index,
      msg: msg,
      callBack: () =>
        setApiStateIndex({
          index: 0,
          msg: "",
        }),
    });
  };
  //#endregion

  //#region VERIFY API STATES
  let verifyApiContent;
  if (apiStateIndex.index === 1) {
    if (isLoadingGetVerifyUserDetailsApi) {
      verifyApiContent = (
        <Backdrop
          open={true}
          sx={{
            color: "rgb(75,73,172)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress />
        </Backdrop>
      );
    } else if (getVerifyUserDetailsAPIError) {
      setApiStateIndex({
        index: 2,
        msg:
          getVerifyUserDetailsAPIError.data?.errorMessage ??
          "Something Went Wrong",
        callBack: () => {
          setApiStateIndex({
            index: 0,
            msg: "",
          });
        },
      });
    } else if (isSuccessGetVerifyUserDetailsAPI) {
      verifyApiContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setApiStateIndex({
              index: 0,
              msg: "",
            });
          }}
          data={{
            title: "Cheers..!",
            msg: "Successful..",
          }}
        />
      );
    }
  } else if (apiStateIndex.index === 2) {
    verifyApiContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          apiStateIndex.callBack();
        }}
        data={{
          title: "Oops!",
          msg: apiStateIndex.msg,
        }}
      />
    );
  } else {
    verifyApiContent = null;
  }
  //#endregion

  return (
    <Fragment>
      <Button
        // disabled={isVerified ? true : false}
        fullWidth
        style={{
          // backgroundColor: `${
          //   isVerified ? "rgb(235,235,228)" : "rgb(75,73,172)"
          // }`,
          backgroundColor: "rgb(75,73,172)",
          color: "white",
        }}
        onClick={checkVerify}
      >
        Verify
      </Button>
      {verifyApiContent}
    </Fragment>
  );
}
