export const baseUrl = "https://api.imigames.io:443/api/v1/";

export const drawerWidth = 200;

export const PathsUrls = {
  login: "/login",
  home: "/competitionList",
  threshold: "/threshold",
};

export const Titles = {
  gameLevelPage: "Competition List",
  thresholdPage: "Threshold",
};

export const gameLevelData = [
  {
    id: 0,
    type: "Easy",
  },
  {
    id: 1,
    type: "Medium",
  },
  {
    id: 2,
    type: "Hard",
  },
];

export const userType = [
  {
    user: "VENDOR1_PLACE1_USER",
    gameUuid: "da96ec96-f976-4f7f-a330-07bd85fa607d",
  },
  {
    user: "VENDOR2_PLACE2_USER",
    gameUuid: "1feb5e3d-cf1b-432a-9510-6507eb5ab58d",
  },
];

export const INFO = "INFO";