import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/Data";

const homeAPI = createApi({
  reducerPath: "homeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getGameCompetitionList: builder.query({
      query: ({ userToken, gameUuid }) => ({
        url: `/developer/fiji/vendor/competition/list/${gameUuid}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }),
    }),
    getUserDetails: builder.mutation({
      query: ({ userToken, userId, rewardId }) => ({
        url: `/developer/fiji/vendor/game-session/${userId}?reward_leaderboard_uuid=${rewardId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }),
    }),
    postMarkPrice: builder.mutation({
      query: ({ userToken, userId, rewardId, gameUuid }) => ({
        url: `/developer/fiji/vendor/prize/mark/${userId}?game_uuid=${gameUuid}&comment=test&reward_leaderboard_uuid=${rewardId}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }),
    }),
    verifyUser: builder.mutation({
      query: ({ userToken, userId, rewardId, score }) => ({
        url: `/developer/fiji/vendor/verify-user/${userId}?reward_leaderboard_uuid=${rewardId}&score=${score}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }),
    }),
  }),
});

export const {
  useGetGameCompetitionListQuery,
  useGetUserDetailsMutation,
  usePostMarkPriceMutation,
  useVerifyUserMutation,
} = homeAPI;
export default homeAPI;
