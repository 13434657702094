import { Box, Typography } from "@mui/material";
import React from "react";
import { drawerWidth } from "../../utils/Data";

export default function PageNotFound() {
  return (
    <Box sx={{ display: "flex", overflow: "hidden" }}>
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
          width: "200px",
        }}
      ></Box>
      <Box
        sx={{
          width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` },
          //backgroundColor:"red",
          marginTop: "36px",
          marginLeft: "15px",
          marginRight: "15px",
        }}
      >
        <Typography
          variant="h5"
          sx={{ color: "rgb(75,73,172)", fontWeight: "bold" }}
        >
          Page Not Found!
        </Typography>
      </Box>
    </Box>
  );
}
