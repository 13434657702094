import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useGetGameCompetitionListQuery,
  useGetUserDetailsMutation,
} from "../../services/HomeApi";
import { homeActions } from "../../store/Store";
import { drawerWidth, INFO, PathsUrls, Titles,} from "../../utils/Data";
import FeedbackDialog from "../../utils/FeedBackDialog";
import MarketPrice from "./marketPrice";
import VerifyUser from "./verifyUser";

export default function Home() {
  //#region API && HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentRewardId = useRef(null);
  const userToken = useSelector((state) => state.home.userToken);
  const gameUuid = useSelector((state) => state.home.gameUuid);
  const [userId, setUserId] = useState("");
  const [competitionRange, setCompetitionRange] = useState("");
  const [apiStateIndex, setApiStateIndex] = useState({
    index: 0,
    msg: "",
    callBack: null,
  });
  const [checkIsValid, setCheckIsValid] = useState(false);
  //#endregion

  //#region CHECK USER TOKEN
  useEffect(() => {
    if (!userToken) {
      let info = JSON.parse(localStorage.getItem(INFO));
      if (info) {
        dispatch(homeActions.setUserToken(info.token));
        dispatch(homeActions.setGameUuid(info.gameUuid));
      } else {
        navigate(PathsUrls.login, { replace: "true" });
      }
    }
  }, []);
  //#endregion

  //#region CHANGE COMPETITION RANGE FN
  const onCompetitionRange = (event) => {
    setCompetitionRange(event.target.value);
    //console.log(event.target.value.split(" ")[0]);
    currentRewardId.current = getGameCompetitionListResponse.data
      .filter(
        (item) =>
          moment(item.start_date).format("YYYY/MM/DD") ===
          event.target.value.split(" ")[0]
      )
      .map((item) => item.uuid)[0];
  };
  //#endregion

  //#region ON CHANGE USER ID FN
  const onChangeUserId = (event) => {
    setUserId(event.target.value);
  };
  //#endregion

  //#region GET GAME COMPETITION LIST
  const {
    data: getGameCompetitionListResponse,
    error: getGameCompetitionListApiError,
    isLoading: isLoadingGetGameCompetitionList,
  } = useGetGameCompetitionListQuery({
    userToken: userToken,
    gameUuid: gameUuid,
  });
  //#endregion

  //#region CHANGE GAME LEVEL BTN FN
  const getUserDetailsBtnClick = () => {
    let index = 0;
    let msg = "";
    if (userId) {
      index = 1;
      msg = "";
      //console.log(currentRewardId.current);
      userDetailsRequest({
        userToken: userToken,
        userId: parseInt(userId),
        rewardId: currentRewardId.current,
      });
    } else {
      index = 2;
      msg = "FIELD CAN'T BE EMPTY";
    }

    setApiStateIndex({
      index: index,
      msg: msg,
      callBack: () =>
        setApiStateIndex({
          index: 0,
          msg: "",
        }),
    });
  };
  //#endregion

  //#region GET GAME COMPETITION LIST API STATES
  let competitionListContent;
  if (isLoadingGetGameCompetitionList) {
    competitionListContent = (
      <Backdrop
        open={true}
        sx={{
          color: "rgb(75,73,172)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress />
      </Backdrop>
    );
  } else if (getGameCompetitionListApiError) {
    //console.log(getGameCompetitionListApiError);
    competitionListContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          navigate(PathsUrls.login, { replace: "true" });
        }}
        data={{
          title: "Oops!",
          msg:
            getGameCompetitionListApiError?.data?.error ??
            "Something Went Wrong",
        }}
      />
    );
  } else if (getGameCompetitionListResponse) {
    //console.log(getGameCompetitionListResponse);
    competitionListContent = (
      <Grid
        spacing={3}
        container
        flexDirection={"column"}
        display={"flex"}
        justifyContent="center"
      >
        <Grid item md={6} lg={6} xs={6}>
          <FormControl
            fullWidth
            sx={{
              "& label.Mui-focused": {
                color: "rgb(75,73,172)",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "rgb(75,73,172)",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgb(75,73,172)",
                },
                "&:hover fieldset": {
                  borderColor: "rgb(75,73,172)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgb(75,73,172)",
                },
              },
            }}
          >
            <Select
              size="small"
              fullWidth
              labelId="statusType"
              id="statusType"
              name="type"
              value={competitionRange}
              onChange={onCompetitionRange}
            >
              {getGameCompetitionListResponse.data.map((item) => {
                return (
                  <MenuItem
                    key={item.id}
                    value={
                      moment(item.start_date).format("YYYY/MM/DD") +
                      " - " +
                      moment(item.end_date).format("YYYY/MM/DD")
                    }
                  >
                    {moment(item.start_date).format("YYYY/MM/DD") +
                      " - " +
                      moment(item.end_date).format("YYYY/MM/DD")}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={6} lg={6} xs={6}>
          <TextField
            sx={{
              "& label.Mui-focused": {
                color: "rgb(75,73,172)",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "rgb(75,73,172)",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgb(75,73,172)",
                },
                "&:hover fieldset": {
                  borderColor: "rgb(75,73,172)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgb(75,73,172)",
                },
              },
            }}
            size="small"
            fullWidth={true}
            id="userID"
            label="User ID"
            placeholder="User ID"
            name="mobileNumber"
            type="number"
            value={userId}
            onChange={onChangeUserId}
          />
        </Grid>
        <Grid item md={6} lg={6} xs={6}>
          <Grid container spacing={3} flexDirection={"row"}>
            <Grid item md={6} lg={6} xs={6}></Grid>
            <Grid item md={6} lg={6} xs={6}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "rgb(75,73,172)",
                  color: "white",
                }}
                onClick={getUserDetailsBtnClick}
              >
                check
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  useEffect(() => {
    if (getGameCompetitionListResponse) {
      setCompetitionRange(
        moment(getGameCompetitionListResponse.data[0].start_date).format(
          "YYYY/MM/DD"
        ) +
          " - " +
          moment(getGameCompetitionListResponse.data[0].end_date).format(
            "YYYY/MM/DD"
          )
      );
      currentRewardId.current = getGameCompetitionListResponse.data
        .filter(
          (item) =>
            item.start_date ===
            getGameCompetitionListResponse.data[0].start_date
        )
        .map((item) => item.uuid)[0];
    }
  }, [getGameCompetitionListResponse]);
  //#endregion

  //#region GAME LEVEL CHANGE API
  const [
    userDetailsRequest,
    {
      isLoading: isLoadingUserDetailsRequestApi,
      error: userDetailsRequestAPIError,
      data: userDetailsRequestAPIData,
    },
  ] = useGetUserDetailsMutation();
  //#endregion

  //#region GAME LEVEL CHANGE API STATES
  let userDetailsContent;
  if (apiStateIndex.index === 1) {
    if (isLoadingUserDetailsRequestApi) {
      userDetailsContent = (
        <Backdrop
          open={true}
          sx={{
            color: "rgb(75,73,172)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress />
        </Backdrop>
      );
    } else if (userDetailsRequestAPIError) {
      setApiStateIndex({
        index: 2,
        msg:
          userDetailsRequestAPIError.data?.errorMessage ??
          "Something Went Wrong",
        callBack: () => {
          setApiStateIndex({
            index: 0,
            msg: "",
          });
        },
      });
    } else if (userDetailsRequestAPIData) {
      //setCheckIsValid(userDetailsRequestAPIData.data.verified);
      //console.log(userDetailsRequestAPIData.data);
      userDetailsContent = userDetailsRequestAPIData.data.session_time ? (
        <Grid
          spacing={3}
          container
          flexDirection={"column"}
          display={"flex"}
          justifyContent="center"
        >
          <Grid item md={6} lg={6} xs={6}>
            <Box
              sx={{
                border: "1px solid rgb(75,73,172)",
                textAlign: "center",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Typography>
                Score : {userDetailsRequestAPIData.data.score}
              </Typography>
              <Typography>
                Time :{" "}
                {moment(userDetailsRequestAPIData.data.session_time).format(
                  "YYYY-MM-DD hh:mm:ss"
                )}
              </Typography>
              <Typography>
                Verify : {checkIsValid ? "true" : "false"}
              </Typography>
              <Typography>
                Prize Marked :{" "}
                {userDetailsRequestAPIData.data.prize_marked ? "true" : "false"}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} lg={6} xs={6}>
            <Grid
              spacing={3}
              container
              flexDirection={"row"}
              justifyContent="space-between"
            >
              <Grid item>
                <MarketPrice
                  userId={userId}
                  rewardId={currentRewardId.current}
                  isVerified={
                    checkIsValid
                      ? userDetailsRequestAPIData.data.prize_marked
                        ? false
                        : true
                      : false
                  }
                  callCheckUserApi={getUserDetailsBtnClick}
                />
              </Grid>
              <Grid item>
                <VerifyUser
                  isVerified={userDetailsRequestAPIData.data.verified}
                  userId={userId}
                  rewardId={currentRewardId.current}
                  score={userDetailsRequestAPIData.data.score}
                  changeStatus={setCheckIsValid}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setApiStateIndex({
              index: 0,
              msg: "",
            });
          }}
          data={{
            title: "Oops!",
            msg: "NO RECORDS AVAILABLE",
          }}
        />
      );
    }
  } else if (apiStateIndex.index === 2) {
    userDetailsContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          apiStateIndex.callBack();
        }}
        data={{
          title: "Oops!",
          msg: apiStateIndex.msg,
        }}
      />
    );
  } else {
    userDetailsContent = null;
  }

  useEffect(() => {
    if (userDetailsRequestAPIData) {
      setCheckIsValid(userDetailsRequestAPIData.data.verified);
    }
  }, [userDetailsRequestAPIData]);
  //#endregion

  return (
    <Box sx={{ display: "flex", overflow: "hidden" }}>
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
          width: "200px",
        }}
      ></Box>
      <Box
        sx={{
          width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` },
          //backgroundColor:"red",
          marginTop: "36px",
          marginLeft: "15px",
          marginRight: "15px",
        }}
      >
        <Typography
          variant="h5"
          sx={{ color: "rgb(75,73,172)", fontWeight: "bold" }}
        >
          {Titles.gameLevelPage}
        </Typography>
        <br />
        {competitionListContent}
        <br />
        {userDetailsContent}
      </Box>
    </Box>
  );
}
