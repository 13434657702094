import { Backdrop, Button, CircularProgress } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { usePostMarkPriceMutation } from "../../services/HomeApi";
import FeedbackDialog from "../../utils/FeedBackDialog";

export default function MarketPrice({
  userId,
  rewardId,
  isVerified,
  callCheckUserApi,
}) {
  //#region API && HOOKS
  const userToken = useSelector((state) => state.home.userToken);
  const gameUuid = useSelector((state) => state.home.gameUuid);
  const [apiStateIndex, setApiStateIndex] = useState({
    index: 0,
    msg: "",
    callBack: null,
  });
  //#endregion

  //#region POST MARKET PRICE API
  const [
    postMarketPriceRequest,
    {
      isLoading: isLoadingPostMarketPriceRequestApi,
      error: postMarketPriceRequestAPIError,
      isSuccess: isSuccessPostMarketPriceRequestAPI,
    },
  ] = usePostMarkPriceMutation();
  //#endregion

  //#region MARKET PRICE BTN CLICK
  const marketPriceBtnClick = () => {
    let index = 0;
    let msg = "";
    if (userId) {
      index = 1;
      msg = "";
      postMarketPriceRequest({
        userToken: userToken,
        userId: userId,
        rewardId: rewardId,
        gameUuid: gameUuid,
      });
    } else {
      index = 2;
      msg = "FIELD CAN'T BE EMPTY";
    }

    setApiStateIndex({
      index: index,
      msg: msg,
      callBack: () =>
        setApiStateIndex({
          index: 0,
          msg: "",
        }),
    });
  };
  //#endregion

  //#region POST MARKET PRICE API STATES
  let postMarketPriceApiContent;
  if (apiStateIndex.index === 1) {
    if (isLoadingPostMarketPriceRequestApi) {
      postMarketPriceApiContent = (
        <Backdrop
          open={true}
          sx={{
            color: "rgb(75,73,172)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress />
        </Backdrop>
      );
    } else if (postMarketPriceRequestAPIError) {
      setApiStateIndex({
        index: 2,
        msg:
          postMarketPriceRequestAPIError.data?.errorMessage ??
          "Something Went Wrong",
        callBack: () => {
          setApiStateIndex({
            index: 0,
            msg: "",
          });
        },
      });
    } else if (isSuccessPostMarketPriceRequestAPI) {
      postMarketPriceApiContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            callCheckUserApi();
            setApiStateIndex({
              index: 0,
              msg: "",
            });
          }}
          data={{
            title: "Cheers..!",
            msg: "Successful..",
          }}
        />
      );
    }
  } else if (apiStateIndex.index === 2) {
    postMarketPriceApiContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          apiStateIndex.callBack();
        }}
        data={{
          title: "Oops!",
          msg: apiStateIndex.msg,
        }}
      />
    );
  } else {
    postMarketPriceApiContent = null;
  }
  //#endregion

  return (
    <Fragment>
      <Button
        // disabled={isVerified ? false : true}
        fullWidth
        style={{
          // backgroundColor: `${
          //  !isVerified ? "rgb(235,235,228)" : "rgb(75,73,172)"
          // }`,
          backgroundColor: "rgb(75,73,172)",
          color: "white",
        }}
        onClick={marketPriceBtnClick}
      >
        Mark Price
      </Button>
      {postMarketPriceApiContent}
    </Fragment>
  );
}
