import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/Data";

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      query: ({ email, password }) => ({
        url: `/user/login`,
        method: "POST",
        headers: {},
        body: {
          email: email,
          password: password,
        },
      }),
    }),
  }),
});

export const { useUserLoginMutation } = loginApi;
