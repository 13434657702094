import { configureStore, createSlice } from "@reduxjs/toolkit";
import homeAPI from "../services/HomeApi";
import { loginApi } from "../services/LoginApi";
import { thresholdApi } from "../services/ThresholdApi";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    userToken: "",
    gameUuid: "",
  },
  reducers: {
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
    setGameUuid: (state, action) => {
      state.gameUuid = action.payload;
    },
  },
});

const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    [homeAPI.reducerPath]: homeAPI.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [thresholdApi.reducerPath]: thresholdApi.reducer,
  },
  //CACHING WILL BE HANDLED BY THE MIDDLEWARE
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(homeAPI.middleware)
      .concat(loginApi.middleware)
      .concat(thresholdApi.middleware),
});

export const homeActions = homeSlice.actions;

export default store;
