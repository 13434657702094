import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useGetGameThresholdQuery,
  useUpdateGameThresholdMutation,
} from "../../services/ThresholdApi";
import { homeActions } from "../../store/Store";
import { drawerWidth, INFO, PathsUrls, Titles} from "../../utils/Data";
import FeedbackDialog from "../../utils/FeedBackDialog";

export default function Threshold() {
  //#region API && HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [gameLevel, setGameLevel] = useState("");
  const userToken = useSelector((state) => state.home.userToken);
  const gameUuid = useSelector((state) => state.home.gameUuid);
  const [apiStateIndex, setApiStateIndex] = useState({
    index: 0,
    msg: "",
    callBack: null,
  });
  const newThreshold = useRef(null);
  //#endregion

  //#region CHECK USER TOKEN
  useEffect(() => {
    if (!userToken) {
      let info = JSON.parse(localStorage.getItem(INFO));
      if (info) {
        dispatch(homeActions.setUserToken(info.token));
        dispatch(homeActions.setGameUuid(info.gameUuid));
      } else {
        navigate(PathsUrls.login, { replace: "true" });
      }
    }
  }, []);
  //#endregion

  //#region CHANGE GAME LEVEL FN
  const onGameLevelChange = (event) => {
    setGameLevel(event.target.value);
    newThreshold.current = getThresholdResponse.data
      .filter((item) => item.level === event.target.value)
      .map((item) => item)[0];
  };
  //#endregion

  //#region GET THRESHOLD LEVEL
  const {
    data: getThresholdResponse,
    error: getThresholdResponseApiError,
    isLoading: isLoadingGetThreshold,
  } = useGetGameThresholdQuery({ userToken: userToken, gameUuid: gameUuid });
  //#endregion

  //#region CHANGE GAME LEVEL BTN FN
  const challengeGameLevelBtnClick = () => {
    if (newThreshold.current) {
      //console.log(newThreshold.current);
      gameLevelChangeRequest({
        userToken: userToken,
        active: true,
        game_id: newThreshold.current.game_id,
        id: newThreshold.current.id,
        level: gameLevel,
        threshold_value: newThreshold.current.threshold_value,
      });
      setApiStateIndex({
        index: 1,
        msg: "",
        callBack: () =>
          setApiStateIndex({
            index: 0,
            msg: "",
          }),
      });
    }
  };
  //#endregion

  //#region GET THRESHOLD LEVEL API CONTENT
  let thresholdLevelContent;
  if (isLoadingGetThreshold) {
    thresholdLevelContent = (
      <Backdrop
        open={true}
        sx={{
          color: "rgb(75,73,172)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress />
      </Backdrop>
    );
  } else if (getThresholdResponseApiError) {
    //console.log(getThresholdResponseApiError);
    thresholdLevelContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          navigate(PathsUrls.login, { replace: "true" });
        }}
        data={{
          title: "Oops!",
          msg:
            getThresholdResponseApiError?.data?.error ?? "Something Went Wrong",
        }}
      />
    );
  } else if (getThresholdResponse) {
    if (getThresholdResponse.data.length > 0) {
      thresholdLevelContent = (
        <Fragment>
          <Typography sx={{ color: "rgb(75,73,172)" }}>
            {`Current Threshold value : ${
              getThresholdResponse.data
                .filter((item) => item.active === true)
                .map((item) => item.level)[0]
            }`}
          </Typography>
          <br />
          <Grid spacing={3} container flexDirection={"column"}>
            <Grid item>
              <Grid spacing={3} container flexDirection={"row"}>
                <Grid item md={6} lg={4} xs={12}>
                  <Button
                    size="medium"
                    fullWidth
                    style={{
                      backgroundColor: "transparent",
                      color: "rgb(75,73,172)",
                      border: "1px solid rgb(75,73,172)",
                    }}
                  >
                    Threshold Level
                  </Button>
                </Grid>
                <Grid item md={6} lg={4} xs={12}>
                  <FormControl
                    fullWidth
                    sx={{
                      "& label.Mui-focused": {
                        color: "rgb(75,73,172)",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "rgb(75,73,172)",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgb(75,73,172)",
                        },
                        "&:hover fieldset": {
                          borderColor: "rgb(75,73,172)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "rgb(75,73,172)",
                        },
                      },
                    }}
                  >
                    <Select
                      size="small"
                      fullWidth
                      labelId="statusType"
                      id="statusType"
                      name="type"
                      value={gameLevel}
                      onChange={onGameLevelChange}
                    >
                      {getThresholdResponse.data.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.level}>
                            {item.level}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid spacing={3} container flexDirection={"row"}>
                <Grid item md={6} lg={4} xs={12}></Grid>
                <Grid item md={6} lg={4} xs={12}>
                  <Button
                    size="medium"
                    fullWidth
                    style={{
                      backgroundColor: "rgb(75,73,172)",
                      color: "white",
                    }}
                    onClick={challengeGameLevelBtnClick}
                  >
                    CHANGE GAME LEVEL
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      );
    } else {
      thresholdLevelContent = "Nothing to show";
    }
  }
  useEffect(() => {
    if (getThresholdResponse) {
      setGameLevel(
        getThresholdResponse.data
          .filter((item) => item.active === true)
          .map((item) => item.level)[0]
      );
      newThreshold.current = getThresholdResponse.data
        .filter((item) => item.active === true)
        .map((item) => item)[0];
    }
  }, [getThresholdResponse]);
  //#endregion

  //#region GAME LEVEL CHANGE API
  const [
    gameLevelChangeRequest,
    {
      isLoading: isLoadingGameLevelChangeRequestApi,
      isSuccess: isSuccessGameLevelChangeRequestAPISuccess,
      error: gameLevelChangeRequestAPIError,
    },
  ] = useUpdateGameThresholdMutation();
  //#endregion

  //#region GAME LEVEL CHANGE API STATES
  let gameLevelChangeContent;
  if (apiStateIndex.index === 1) {
    if (isLoadingGameLevelChangeRequestApi) {
      gameLevelChangeContent = (
        <Backdrop
          open={true}
          sx={{
            color: "rgb(75,73,172)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress />
        </Backdrop>
      );
    } else if (gameLevelChangeRequestAPIError) {
      setApiStateIndex({
        index: 2,
        msg:
          gameLevelChangeRequestAPIError.data?.errorMessage ??
          "Something Went Wrong",
        callBack: () => {
          setApiStateIndex({
            index: 0,
            msg: "",
          });
        },
      });
    } else if (isSuccessGameLevelChangeRequestAPISuccess) {
      gameLevelChangeContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setApiStateIndex({
              index: 0,
              msg: "",
            });
          }}
          data={{
            title: "Cheers..!",
            msg: "Updated..",
          }}
        />
      );
    }
  } else if (apiStateIndex.index === 2) {
    gameLevelChangeContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          apiStateIndex.callBack();
        }}
        data={{
          title: "Oops!",
          msg: apiStateIndex.msg,
        }}
      />
    );
  } else {
    gameLevelChangeContent = null;
  }
  //#endregion

  return (
    <Box sx={{ display: "flex", overflow: "hidden" }}>
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
          width: "200px",
        }}
      ></Box>
      <Box
        sx={{
          width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` },
          //backgroundColor:"red",
          marginTop: "36px",
          marginLeft: "15px",
          marginRight: "15px",
        }}
      >
        <Typography
          variant="h5"
          sx={{ color: "rgb(75,73,172)", fontWeight: "bold" }}
        >
          {Titles.thresholdPage}
        </Typography>
        {thresholdLevelContent}
        <br />
        {gameLevelChangeContent}
      </Box>
    </Box>
  );
}
