import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/Data";

export const thresholdApi = createApi({
  reducerPath: "thresholdApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getGameThreshold: builder.query({
      query: ({ userToken, gameUuid }) => ({
        url: `/developer/fiji/vendor/game-threshold/full/${gameUuid}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }),
      providesTags: ["threshold"],
    }),
    updateGameThreshold: builder.mutation({
      query: ({ userToken, active, game_id, id, level, threshold_value }) => ({
        url: `/developer/fiji/vendor/game-threshold`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: {
          active: active,
          game_id: game_id,
          id: id,
          level: level,
          threshold_value: threshold_value,
        },
      }),
      invalidatesTags: ["threshold"],
    }),
  }),
});

export const { useGetGameThresholdQuery,useUpdateGameThresholdMutation } = thresholdApi;
